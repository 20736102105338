

















































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Table from '@/components/Table/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import RemarkModal from '@/views/common/remark-modal.vue'
import { getDicts, getDictName } from '@/utils/dict'
import { Select, Option } from 'element-ui'
Vue.use(Select)
Vue.use(Option)

@Component({
  components: {
    Table,
    Pagination,
    RemarkModal
  }
})
export default class OperationList extends Vue {
  // 字典数据
  // 设备维护状态
  deviceStatusDicts: any = []
  // 设备类型
  deviceTypeDicts: any = []
  status = ''
  listLoading = false
  tableHeader = [
    {
      key: 'deviceType',
      name: '设备类型',
      width: '10%',
      isSpecial: true
    },
    {
      key: 'deviceModel',
      name: '设备型号',
      width: ''
    },
    {
      key: 'happenTime',
      name: '发生时间',
      width: '12%'
    },
    {
      key: 'status',
      name: '设备状态',
      width: '12%',
      isSpecial: true
    },
    {
      key: 'deviceGroupName',
      name: '设备组',
      width: '12%'
    },
    {
      key: 'deviceName',
      name: '设备名称',
      width: '12%'
    },
    {
      key: 'remark',
      name: '备注',
      width: '12%',
      remark: true
    },
    {
      key: 'opt',
      name: '操作',
      width: '12%',
      isSpecial: true
    }
  ]
  tableData: any = []
  layout = 'total, sizes, prev, pager, next, jumper'
  total = 0
  listQuery = {
    page: 1,
    limit: 15
  }
  isOpenModal = false
  deviceId = 0
  deviceRemark = ''
  remarkLoading = false

  async beforeCreate() {
    // 获取相关字典
    // 设备类型
    this.deviceTypeDicts = await getDicts('DEVICE_TYPE')
    // 设备维护状态
    this.deviceStatusDicts = await getDicts('DEVICE_OPERATION_STATUS')
  }

  created() {
    this.getEquipmentList()
  }

  changeStatus(item: any) {
    this.listLoading = true
    this.$api.equipment
      .changeStatus({
        id: item.id,
        status: 1
      })
      .then(() => {
        this.listLoading = false
        this.getEquipmentList()
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  searchFn() {
    this.listQuery.page = 1
    this.getEquipmentList()
  }

  // 获取设备运维列表
  getEquipmentList() {
    this.listLoading = true
    this.$api.equipment
      .getEquipOptList({
        current: this.listQuery.page,
        size: this.listQuery.limit,
        status: this.status
      })
      .then((res: any) => {
        this.listLoading = false
        const data = res.data
        this.tableData = data.data
        this.total = data.total
      })
      .catch(() => {
        this.listLoading = false
      })
  }

  // 添加备注
  addRemark(remark: string) {
    this.remarkLoading = true
    this.$api.equipment
      .addOptRemark({
        id: this.deviceId,
        remark
      })
      .then((res: any) => {
        this.remarkLoading = false
        const data = res.data
        if (data.success) {
          this.$message.success('添加备注成功')
          this.getEquipmentList()
          this.closeDialog()
        } else {
          this.$message.error(data.msg)
        }
      })
      .catch(() => {
        this.remarkLoading = false
        this.$message.error('添加备注失败')
      })
  }

  // 获取字典中值对应的名称
  filterDictName(dict: any, val: any) {
    return dict.length === 0 ? '' : getDictName(dict, val)
  }

  // 关闭对话框
  closeDialog() {
    this.isOpenModal = false
  }
  // 操作对话框后统一回调
  afterOpt(data: any) {
    this.addRemark(data)
  }
}
