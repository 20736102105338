var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"operation"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('el-option',{attrs:{"label":"全部状态","value":""}}),_vm._l((_vm.deviceStatusDicts),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.name,"value":item.value}})})],2),_c('div',{staticStyle:{"height":"32px"},on:{"click":_vm.searchFn}},[_c('SvgIcon',{attrs:{"iconClass":"searchIcon","className":"icon"}})],1)],1)]),_c('Table',{attrs:{"contHeight":320,"pageInfo":_vm.listQuery,"loading":_vm.listLoading,"header":_vm.tableHeader,"content":_vm.tableData},scopedSlots:_vm._u([{key:"deviceType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.filterDictName(_vm.deviceTypeDicts, item.deviceType))+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(("color:" + (item.status === 0 ? '#e77151' : '')))},[_vm._v(" 故障"+_vm._s(_vm.filterDictName(_vm.deviceStatusDicts, item.status + ''))+" ")])]}},{key:"opt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"opt"},[(item.status === 0)?_c('span',{staticClass:"txt",on:{"click":function($event){return _vm.changeStatus(item)}}},[_vm._v("已维护")]):_vm._e(),_c('span',{on:{"click":function($event){_vm.isOpenModal = true
              _vm.deviceId = item.id
              _vm.deviceRemark = item.remark}}},[_vm._v(" 添加备注 ")])])]}}])}),_c('div',{staticClass:"pagination"},[_c('pagination',{attrs:{"total":_vm.total,"page":_vm.listQuery.page,"limit":_vm.listQuery.limit,"layout":_vm.layout},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery, "limit", $event)},"pagination":_vm.getEquipmentList}})],1)],1),_c('RemarkModal',{attrs:{"isShow":_vm.isOpenModal,"loading":_vm.remarkLoading,"reason":_vm.deviceRemark},on:{"optSuccess":_vm.afterOpt,"close":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }