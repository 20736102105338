/* eslint-disable @typescript-eslint/no-explicit-any */
import { common } from '@/api/common'

/**
 * 获取某个字典数据
 * @param groupCode 字典组编码
 * @returns 字典数据
 */
export const getDicts = async (groupCode: string) => {
  let dicts
  await common
    .getDictList({
      groupCode
    })
    .then((res: any) => {
      if (res.data.code === '200') {
        dicts = res.data.data
      }
    })
  return dicts
}

/**
 * 获取字典中的某个值的名称
 * @param dicts 字典数据
 * @param value 某值
 * @returns 值对应的名称
 */
export const getDictName = (dicts: any, value: any) => {
  const dict = dicts.filter((item: any) => item.value == value)
  if (dict && dict[0]) {
    return dict[0].name
  } else {
    return ''
  }
}
