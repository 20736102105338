





















/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Dialog from '@/components/Dialog/index.vue'

@Component({
  components: {
    Dialog
  }
})
export default class extends Vue {
  @Prop({ default: false }) private isShow!: boolean
  @Prop({ default: false }) private loading!: boolean
  @Prop({ default: '' }) private reason!: string
  @Prop({ default: '添加备注' }) private title!: string

  // 监听器
  @Watch('reason')
  getreason(val: string) {
    this.data = val
  }

  data = this.reason

  closeDialog() {
    this.data = this.reason
    this.$emit('close', '')
  }
  dialogConfirm() {
    if (this.data === '') {
      this.$message.warning('请先填写备注')
    } else {
      this.$emit('optSuccess', this.data)
    }
  }
}
